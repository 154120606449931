<template>
  <div class="pa-3">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-dialog
        v-model="showForm"
        max-width="800px"
        persistent
        transition="dialog-top-transition"
        @keydown.esc="close"
      >
        <v-card>
          <v-card-title>
            Configuration Output pour : {{ product_in.product_name }}
          </v-card-title>
          <v-divider></v-divider>

          <v-card-text class="mt-12">
            <v-row class="mb-n13">
              <v-col cols="12" sm="11" md="11" lg="11" class="mb-n13">
                <v-slider
                  readonly
                  v-model="total"
                  :max="total + reste"
                  thumb-label="always"
                  :color="set_ok && reste > 0 ? 'red' : 'green'"
                  :track-color="set_ok && reste > 0 ? 'red' : 'green'"
                >
                  <template v-slot:prepend>
                    <v-chip small color="green">
                      Utilisé :
                      {{ total + " " + unit }}
                    </v-chip>
                  </template>

                  <template v-slot:append>
                    <v-chip
                      small
                      :color="set_ok && reste > 0 ? 'red' : 'green'"
                      v-if="set_ok && reste > 0"
                    >
                      Reste :
                      {{ reste + " " + unit }}
                    </v-chip>
                  </template>
                  <template v-slot:thumb-label>
                    {{ "-" + reste }}
                  </template>
                </v-slider>
              </v-col>
              <v-col cols="12" sm="11" md="11">
                <v-autocomplete
                  v-model="article_id"
                  :items="articles_list"
                  item-value="id"
                  label="Produit"
                  chips
                  solo
                  dark
                  class="mt-2"
                  :disabled="reste == 0"
                >
                  <template v-slot:selection="{ item }">
                    <!-- HTML that describe how select should render selected items -->
                    {{ (item.code ? item.code + "-" : "") + item.label }}
                  </template>
                  <template slot="item" slot-scope="data">
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="data.item.code + '-' + data.item.label"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="1" md="1">
                <v-btn
                  fab
                  dark
                  color="indigo"
                  @click="update_product"
                  class="mt-1"
                  :disabled="!article_id || set_ok || reste == 0"
                >
                  <v-icon dark> mdi-plus </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="7" md="12" lg="12">
                <v-sheet elevation="8" rounded="lg" color="grey darken-2">
                  <div class="pa-4 my-1">
                    <v-chip-group active-class="primary--text" column>
                      <v-chip
                        color="green"
                        v-for="product in articles_sel"
                        :key="product.id"
                        close
                        @click:close="delchips(product)"
                      >
                        {{ product.caption }}
                      </v-chip>
                    </v-chip-group>
                  </div>
                </v-sheet>
              </v-col>
              <!-- 
              <v-col cols="12" sm="7" md="6" lg="6">
                <v-chip>
                  Total :
                  {{ total + " " + unit }}
                </v-chip>
              </v-col>
              <v-col cols="12" sm="7" md="6" lg="6">
                <v-chip :color="set_ok && reste > 0 ? 'red' : 'green'">
                  Reste :
                  {{ reste + " " + unit }}
                </v-chip>
              </v-col> -->
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn
              class="ml-2"
              color="indigo"
              :dark="set_ok"
              @click="save"
              :disabled="!set_ok"
            >
              <v-icon> mdi-save </v-icon>
              Enregistrer
            </v-btn>

            <v-spacer></v-spacer>
            <v-btn color="indigo" @click="close" class="ml-2" dark>
              Fermer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
    <confirmdialog ref="confirm" />
  </div>
</template>

<script>
export default {
  name: "articleselectform",
  components: {
    confirmdialog: () => import("./ConfirmDialog.vue"),
  },
  props: {
    showForm: Boolean,
    articles: Array,
    selected_articles: Array,
    attributes: Array,
    product_in: Object,
  },
  data: () => ({
    afficher: false,
    valid: true,
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    articles_sel: [],
    article_id: null,
    control_id: null,
    control_name: null,
    control_caption: null,
    control_value: 0,
    set_ok: false,
    unit: "",
  }),

  computed: {
    total() {
      return this.articles_sel.reduce((a, b) => a + (b["value"] || 0), 0);
    },
    reste() {
      return this.control_value - this.total;
    },
    articles_list() {
      let list = [];
      for (let index = 0; index < this.articles.length; index++) {
        const element = this.articles[index];
        let att = JSON.parse(element.attributs_json);
        let j = att
          .filter((elm) => elm.attribute_id == this.control_id)
          .findIndex((elm) => parseFloat(elm.value) <= this.reste);
        if (j >= 0) {
          list.push(element);
        }
      }
      return list;
    },
  },
  watch: {},

  created() {
    if (this.product_in.attributs_json) {
      let att = JSON.parse(this.product_in.attributs_json);
      let j = -1;
      for (let index = 0; index < att.length; index++) {
        const element = att[index];
        j = this.attributes.findIndex(
          (elm) => elm.id == element.attribute_id && elm.control == "1"
        );
        if (j >= 0) break;
      }

      if (j >= 0) {
        this.control_id = this.attributes[j].id;
        this.unit = this.attributes[j].unit;
        this.control_name = this.attributes[j].name
          ? this.attributes[j].name
          : null;
        this.control_caption = this.attributes[j].caption
          ? this.attributes[j].caption
          : null;
        this.control_value = parseFloat(att[j].value);
      }
    }
    if (this.selected_articles) {
      this.articles_sel = this.selected_articles;
    }
  },

  methods: {
    delchips(item) {
      let i = this.articles_sel.findIndex((elm) => elm.id == item.id);
      if (i >= 0) {
        this.articles_sel.splice(i, 1);
        this.verif_ok();
      }
    },
    close() {
      this.$emit("close");
    },
    update_product() {
      let article;
      let i = this.articles.findIndex((elm) => elm.id == this.article_id);
      if (i >= 0) {
        article = this.articles[i];
        let att = JSON.parse(article.attributs_json);
        let l = att.findIndex((elm) => elm.attribute_id == this.control_id);

        if (
          l >= 0 &&
          this.total + parseFloat(att[l].value) <= this.control_value
        ) {
          let t = new Date().getTime();
          this.articles_sel.push({
            id: t,
            article_id: article.id,
            value: parseFloat(att[l].value),
            caption:
              (this.control_caption
                ? this.control_caption
                : this.control_name) +
              " " +
              att[l].value +
              " " +
              this.unit,
            article: article,
          });
          this.verif_ok();
        }
      }
    },
    verif_ok() {
      if (this.reste == 0) this.set_ok = true;
      else {
        let d = this.control_value - this.total;

        //search product for the rest
        this.set_ok = true;
        for (let index = 0; index < this.articles.length; index++) {
          const element = this.articles[index];
          let att = JSON.parse(element.attributs_json);
          let j = att
            .filter((elm) => elm.attribute_id == this.control_id)
            .findIndex((elm) => parseFloat(elm.value) <= d);
          if (j >= 0) {
            this.set_ok = false;
            break;
          }
        }
      }
    },

    async save() {
      let ok = true;
      if (this.reste > 0) {
        if (
          await this.$refs.confirm.open(
            "Attention",
            this.control_name +
              " " +
              this.control_value +
              " " +
              this.unit +
              " (reste  " +
              this.reste +
              " " +
              this.unit +
              " considéré comme dechet)" +
              "-Etês-vous sûr ?",
            { color: "orange darken-3" }
          )
        ) {
          ok = true;
          this.articles_sel.push({
            id: new Date().getTime(),
            article_id: null,
            value: this.reste,
            caption:
              (this.control_caption
                ? this.control_caption
                : this.control_name) +
              " " +
              this.reste +
              " " +
              this.unit +
              " (dechets)",
          });
        } else ok = false;
      }
      if (ok) this.$emit("save", this.articles_sel);
    },
  },
};
</script>
